import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import theme from '../styles/theme';
import NotFound from '../components/404';
import Footer from '../components/Footer';

const AboutPage = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <NotFound />
    <Footer />
  </MuiThemeProvider>
);
export default AboutPage;
