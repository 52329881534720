import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Header from '../Header';
import Typography from '../Typography';

const useStyles = makeStyles({
  header: {
    marginTop: '70px',
    paddingTop: '10vh',
  },
  info: {
    padding: '50px',
  },
});
export default function NotFound() {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Paper elevation={0} style={{ minHeight: '55vh' }}>
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid key={1} item xs={12} md={12}>
              <Typography
                variant="h3"
                align="center"
                style={{ paddingTop: '20px' }}
              >
                Page Not Found!
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                align="center"
                className={classes.info}
              >
                Ooops! The page you are looking for does not exist!
              </Typography>
            </Grid>
          </Grid>
        </section>
      </Paper>
    </>
  );
}
